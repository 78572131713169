import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import Info from '@material-ui/icons/Info'

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
})

export default function SideDrawer(props) {
  const { toggleDrawer } = props
  const classes = useStyles()

  const sideList = () => (
    <div className={classes.list} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
      <List>
        <ListItem button key="info">
          <ListItemIcon>
            <Info />
          </ListItemIcon>
          <ListItemText primary="Created by Griff" />
        </ListItem>
      </List>
      <Divider />
    </div>
  )

  return (
    <div>
      <Drawer open={props.open} onClose={toggleDrawer(false)}>
        {sideList('left')}
      </Drawer>
    </div>
  )
}
