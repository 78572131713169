import React from 'react'

//material ui
import { Grid, Paper, Typography, Box, Button, List, Divider, TextField, Checkbox, FormGroup, FormControlLabel, FormControl, MenuItem, Select, InputLabel } from '@material-ui/core'

import AddBoxRounded from '@material-ui/icons/AddBoxRounded'
import DeleteSharp from '@material-ui/icons/DeleteSharp'
import EditSharp from '@material-ui/icons/EditSharp'
import FileCopy from '@material-ui/icons/FileCopy'

//utils
import { DateTime } from 'luxon'

import { connect } from 'react-redux'

//selectors
import { getBurnrateStats, getProjectionsByMonth } from '../selectors'

//actions
import { copyProfile, renameProfile, editProfile, deleteProfile, addIncome, addExpense, editIncome, editExpense, deleteExpense, deleteIncome, setCurrent, setTarget } from '../actions'

//components
import { EditableListItem, Accounts, SpendingMoney, TimeTravel } from './'

import HelperTooltip from './Shared/HelperTooltip'

const mapStateToProps = (state) => {
  return {
    targetAccount: state.targetAccount,
    currentAccount: state.currentAcount,
    stats: getBurnrateStats(state.profiles[state.currentProfile]),
    monthly: getProjectionsByMonth(state.profiles[state.currentProfile]),
    incomes: state.incomes,
    expenses: state.expenses,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    copyProfile: (profile) => dispatch(copyProfile(profile)),
    editProfile: (profile) => dispatch(editProfile(profile)),
    deleteProfile: (profile) => dispatch(deleteProfile(profile)),
    renameProfile: (payload) => dispatch(renameProfile(payload)),
    addIncome: (income) => dispatch(addIncome(income)),
    editIncome: (income) => dispatch(editIncome(income)),
    addExpense: (expense) => dispatch(addExpense(expense)),
    editExpense: (expense) => dispatch(editExpense(expense)),
    deleteExpense: (expense) => dispatch(deleteExpense(expense)),
    deleteIncome: (income) => dispatch(deleteIncome(income)),
    setTarget: (target) => dispatch(setTarget(target)),
    setCurrent: (current) => dispatch(setCurrent(current)),
  }
}

function changeProfileName(changeProfileFunc, profile) {
  const profileName = prompt('Change Profile Name?', profile.name)
  //ensure data
  if (!profileName || profileName === '') {
    return
  }
  changeProfileFunc({ id: profile.id, name: profileName })
}

const IncomesComp = (props) => {
  const { incomes, name, handleDelete, handleEdit, handleAdd, calculatedTotal } = props
  return (
    <Paper style={{ padding: 20, flexGrow: 1 }}>
      <Grid container space={1} alignItems="center">
        <Grid item>
          <Typography gutterBottom variant="h5" component="h2">
            Income
          </Typography>
        </Grid>
        <Grid item style={{ flexGrow: 1 }} />
        <Grid item>
          <HelperTooltip title="What's this for?">Tracking your monthly income.</HelperTooltip>
        </Grid>
      </Grid>

      <hr />
      <div style={{ maxHeight: 700, overflow: 'auto' }}>
        <List>
          {incomes.map((income, index) => (
            <EditableListItem label="Income" onDelete={handleDelete} onEdit={handleEdit} key={income.id + name} data={{ ...income, index }} />
          ))}
        </List>
      </div>
      <hr />
      <div>
        <Grid justify="space-between" alignItems="center" container spacing={2}>
          <Grid item>
            <Typography>Total: ${calculatedTotal}</Typography>
          </Grid>
          <Grid item>
            <Button
              raised={1}
              onClick={() =>
                handleAdd({
                  name: 'New Income',
                  amount: 0,
                  interval: 'monthly',
                  basis: Date.now(),
                })
              }
            >
              New Income
              <AddBoxRounded />
            </Button>
          </Grid>
        </Grid>
      </div>
    </Paper>
  )
}

const ExpensesComp = (props) => {
  const { expenses, name, handleDelete, handleEdit, handleAdd, calculatedTotal } = props
  return (
    <Paper style={{ padding: 20, flexGrow: 1 }}>
      <Grid container space={1} alignItems="center">
        <Grid item>
          <Typography gutterBottom variant="h5" component="h2">
            Expenses
          </Typography>
        </Grid>
        <Grid item style={{ flexGrow: 1 }} />
        <Grid item>
          <HelperTooltip title="What's this for?">Tracking your montly fixed expenses. If the expense is likely to be highly variable, it's recommended to consider that part of your "spending money" budget.</HelperTooltip>
        </Grid>
      </Grid>
      <hr />
      <div style={{ maxHeight: 700, overflow: 'auto' }}>
        <List>
          {expenses.map((expense, index) => (
            <EditableListItem label="Expense" onDelete={handleDelete} onEdit={handleEdit} key={expense.id + name} data={{ ...expense, index }} />
          ))}
        </List>
      </div>
      <hr />
      <Grid justify="space-between" alignItems="center" container spacing={2}>
        <Grid item>
          <Typography>Total: ${calculatedTotal}</Typography>
        </Grid>
        <Grid item>
          <Button
            raised={1}
            onClick={() =>
              handleAdd({
                name: 'New Expense',
                amount: 0,
                interval: 'monthly',
                basis: Date.now(),
              })
            }
          >
            New Expense
            <AddBoxRounded />
          </Button>
        </Grid>
      </Grid>
    </Paper>
  )
}

const Main = (props) => {
  const { stats } = props
  const { name, incomes, expenses, accounts, timeStart, timeEnd } = props.profile

  //console.log(props.monthly)

  let ts
  if (timeStart === 'now') {
    ts = DateTime.fromMillis(Date.now())
  } else {
    ts = DateTime.fromMillis(timeStart)
  }

  const te = DateTime.fromMillis(timeEnd)

  const handleStartDateChange = (value) => {
    props.editProfile({
      timeStart: value,
    })
  }

  const handleEndDateChange = (value) => {
    props.editProfile({
      timeEnd: value,
    })
  }

  return (
    <Box style={{ paddingLeft: 8, paddingRight: 8 }}>
      <Grid container spacing={2} style={{ marginTop: 0, paddingTop: 0, paddingBottom: 5 }}>
        <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
          <Grid container>
            <Grid item xs={12} md={6} style={{ padding: 10, display: 'flex', alignItems: 'center', justify: 'flex-start' }}>
              <Typography display="inline" variant="h6">
                {name}
              </Typography>
              <Button onClick={() => changeProfileName(props.renameProfile, props.profile)}>
                <EditSharp fontSize="small" />
              </Button>
              <Button onClick={() => window.confirm('Duplicate profile ' + name + '?') && props.copyProfile(props.profile)}>
                <FileCopy fontSize="small" />
              </Button>
              <Button onClick={() => window.confirm('Delete profile ' + name + '?') && props.deleteProfile(props.profile)}>
                <DeleteSharp fontSize="small" />
              </Button>
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: 10, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <Typography display="inline" variant="h6" style={{ fontWeight: 400, marginRight: 10 }}>
                From:
              </Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={timeStart === 'now'}
                      onChange={(e) => {
                        handleStartDateChange(e.target.checked ? 'now' : ts.toMillis())
                      }}
                    />
                  }
                  label="Now"
                />
              </FormGroup>
              <TextField
                name="timeStart"
                type="date"
                disabled={timeStart === 'now'}
                value={ts.toISODate()}
                onChange={(e) => {
                  handleStartDateChange(DateTime.fromISO(e.target.value).toMillis())
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Typography display="inline" variant="h6" style={{ fontWeight: 400, marginLeft: 10, marginRight: 10 }}>
                To:
              </Typography>
              {/* <FormControl style={{ marginRight: 10 }}>
            <Select labelId="end-date-type-select-label" id="end-date-type-select" value={'date'} label="Interval" name="interval">
              <MenuItem value="date">Date</MenuItem>
              <MenuItem value="biWeekly">Bi-Weekly</MenuItem>
              <MenuItem value="monthly">Monthly</MenuItem>
              <MenuItem value="once">Once</MenuItem>
            </Select>
          </FormControl> */}
              <TextField
                name="timeEnd"
                type="date"
                value={te.toISODate()}
                onChange={(e) => {
                  handleEndDateChange(DateTime.fromISO(e.target.value).toMillis())
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <Grid style={{ marginTop: 5, padding: 20, paddingTop: 0 }} container spacing={2}>
        <Grid item md={4} xs={12} style={{ display: 'flex' }}>
          <ExpensesComp expenses={expenses} name={name} handleDelete={props.deleteExpense} handleEdit={props.editExpense} handleAdd={props.addExpense} calculatedTotal={stats.totalCalculatedExpense} />
        </Grid>

        <Grid item md={4} xs={12} style={{ display: 'flex' }}>
          <IncomesComp incomes={incomes} name={name} handleDelete={props.deleteIncome} handleEdit={props.editIncome} handleAdd={props.addIncome} calculatedTotal={stats.totalCalculatedIncome} />
        </Grid>
        <Grid item md={4} xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <SpendingMoney name={name} data={props} />
            </Grid>
            <Grid item xs={12} md={12}>
              <Accounts style={{ padding: 20 }} accounts={accounts} timeStartDT={ts} />
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid item md={6} xs={12}></Grid> */}
        <Grid item md={12} xs={12} lg={6}>
          <TimeTravel name={name} profile={props.profile} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Main)
