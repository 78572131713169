export const CHILD_PROFILE = 'CHILD_PROFILE'
export const ADD_PROFILE = 'ADD_PROFILE'
export const COPY_PROFILE = 'COPY_PROFILE'
export const SWITCH_PROFILE = 'SWITCH_PROFILE'
export const DELETE_PROFILE = 'DELETE_PROFILE'
export const EDIT_PROFILE = 'EDIT_PROFILE'
export const RENAME_PROFILE = 'RENAME_PROFILE'

export const ADD_ACCOUNT = 'ADD_ACCOUNT'
export const EDIT_ACCOUNT = 'EDIT_ACCOUNT'
export const DELETE_ACCOUNT = 'DELETE_ACCOUNT'

export const CLEAR_STATE = 'CLEAR_STATE'

export const ADD_INCOME = 'ADD_INCOME'
export const EDIT_INCOME = 'EDIT_INCOME'
export const DELETE_INCOME = 'DELETE_INCOME'

export const ADD_EXPENSE = 'ADD_EXPENSE'
export const EDIT_EXPENSE = 'EDIT_EXPENSE'
export const DELETE_EXPENSE = 'DELETE_EXPENSE'

export const SET_TARGET = 'SET_TARGET'
export const SET_CURRENT = 'SET_CURRENT'

export const REPLACE_STATE = 'REPLACE_STATE'

export const CHANGE_TIME = 'CHANGE_TIME'
