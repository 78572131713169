import React from 'react'

//material-ui
import Button from '@material-ui/core/Button'

// Inspired by the Facebook spinners.

function ProfileButton(props) {
  return (
    <Button fullWidth={true} style={props.style} onDoubleClick={props.onDoubleClick} onClick={props.onClick} variant={props.variant}>
      {
        <div>
          {/* <BorderLinearProgress value={0} variant="determinate" /> */}
          {props.name}
          {/* <br/>
                ${_.round(props.stats.profTotal,2)}
                <br/>
                line 3 */}
        </div>
      }
    </Button>
  )
}

export default ProfileButton
