import { createSelector } from 'reselect'
import _, { isFinite } from 'lodash'

import { DateTime, Interval } from 'luxon'

const getTimeStartDT = (profileState) => {
  let ts
  if (profileState.timeStart === 'now') {
    ts = DateTime.fromMillis(Date.now())
  } else {
    ts = DateTime.fromMillis(profileState.timeStart)
  }
  return ts
}

const getCalculatedAmount = ({ amountItems, ts, te }) => {
  const timeInterval = Interval.fromDateTimes(ts, te)
  let totalCalculatedIncome = 0
  for (const item of amountItems) {
    //TODO: this whole block is risky and ugly. Refactor.
    if (item.interval === 'biWeekly') {
      let basisDT = DateTime.fromMillis(item.basis).startOf('day')
      const basisIsBefore = timeInterval.isAfter(basisDT)
      if (!basisDT.isValid || !timeInterval.isValid || basisDT.startOf('day') === te.startOf('day')) {
        //skipping to prevent endless loop
        continue
      }
      // NEED TO FIX: basis' ahead of interval will not work properly
      while (!timeInterval.contains(basisDT)) {
        if (timeInterval.isBefore(basisDT)) {
          //past it up
          break
        }
        if (basisIsBefore) {
          basisDT = basisDT.plus({ weeks: 2 })
        } else {
          basisDT = basisDT.minus({ weeks: 2 })
        }
      }
      const incomeMultiple = Math.floor(Interval.fromDateTimes(basisDT, te).length('weeks') / 2) + 1
      totalCalculatedIncome += isFinite(incomeMultiple) ? incomeMultiple * item.amount : 0
    } else if (item.interval === 'once') {
      let basisDT = DateTime.fromMillis(item.basis).startOf('day')
      if (timeInterval.contains(basisDT)) {
        totalCalculatedIncome += item.amount
      }
    } else if (item.interval === 'monthly') {
      let basisDT = DateTime.fromMillis(item.basis).startOf('day')
      const basisIsBefore = timeInterval.isAfter(basisDT)
      if (!basisDT.isValid || !timeInterval.isValid || basisDT.startOf('day') === te.startOf('day')) {
        //skipping to prevent endless loop
        continue
      }
      // NEED TO FIX: basis' ahead of interval will not work properly
      while (!timeInterval.contains(basisDT)) {
        if (timeInterval.isBefore(basisDT)) {
          //past it up
          break
        }
        if (basisIsBefore) {
          basisDT = basisDT.plus({ months: 1 })
        } else {
          basisDT = basisDT.minus({ months: 1 })
        }
      }
      const incomeMultiple = Math.floor(Interval.fromDateTimes(basisDT, te).length('months')) + 1
      totalCalculatedIncome += isFinite(incomeMultiple) ? incomeMultiple * item.amount : 0

      //totalCalculatedIncome += item.amount * Math.floor(timeInterval.length('months'))
    } else if (item.interval === 'weekly') {
      totalCalculatedIncome += item.amount * Math.floor(timeInterval.length('weeks'))
    }
  }
  return totalCalculatedIncome
}

const statsFromProfileState = (profileState, tsDate, teDate) => {
  const ts = tsDate || getTimeStartDT(profileState).startOf('day')
  const te = teDate || DateTime.fromMillis(profileState.timeEnd).endOf('day')
  const timeInterval = Interval.fromDateTimes(ts, te)

  const durationInMonths = timeInterval.length('months')

  //const totalAccountExpense = profileState.accounts.reduce((final, i) => final + i.monthly, 0)

  //calculate total income over time range
  const totalCalculatedIncome = getCalculatedAmount({ amountItems: profileState.incomes, ts, te })

  const totalCalculatedExpense = getCalculatedAmount({ amountItems: profileState.expenses, ts, te })

  const totalMonthlyExpense = profileState.expenses.reduce((final, i) => final + i.amount, 0)
  const netTotal = totalCalculatedIncome - totalCalculatedExpense
  const dailyTotal = netTotal / timeInterval.length('days')
  const weeklyTotal = netTotal / timeInterval.length('weeks')
  const monthlyTotal = netTotal / timeInterval.length('months')
  const yearlyTotal = (totalCalculatedIncome - totalCalculatedExpense) * 12
  const profTotal = yearlyTotal * ((profileState.timeEnd - profileState.timeStart) * 0.00000000003171)

  //TODO: break up this function so the var names can be a little cleaner
  const accountsTotalExtractionPercentage = profileState.accounts.reduce((acc, account) => acc + (!account.spend ? account.monthly : 0), 0) / 100

  const accountsAdditionAmountTotal = profileState.accounts.reduce((acc, account) => acc + (account.spend ? account.spendAmount : 0), 0)
  //const accountsAdditionAmountTotal = accountsMonthlySpendingAdditionAmount * durationInMonths

  const accountsTotal = profileState.accounts.reduce((acc, account) => acc + account.current, 0)
  const accountsNet = profileState.accounts.reduce((acc, account) => acc + (account.debt ? -1 * account.current : account.current), 0)

  //!account.debt ? account.current + stats.netTotal * (account.monthly / 100) : account.current - stats.netTotal * (account.monthly / 100)
  const accountsTotalProjected = accountsTotal + accountsTotalExtractionPercentage * netTotal - accountsAdditionAmountTotal //

  const spendingMonthly = accountsAdditionAmountTotal / durationInMonths + monthlyTotal - monthlyTotal * accountsTotalExtractionPercentage
  const spendingNetTotal = accountsAdditionAmountTotal + netTotal - netTotal * accountsTotalExtractionPercentage

  return {
    durationInMonths,
    accountsTotalProjected,
    accountsNet,
    accountsTotal,
    totalCalculatedIncome,
    totalCalculatedExpense,
    totalMonthlyExpense,
    dailyTotal,
    weeklyTotal,
    monthlyTotal,
    netTotal,
    spendingNetTotal,
    spendingMonthly,
    yearlyTotal,
    profTotal,
  }
}

export const getBurnrateStats = createSelector(
  (state) => state,
  (profileState) => {
    return statsFromProfileState(profileState)
  }
)

//expects accounts
export const getAccountProjections = createSelector(
  (accounts) => accounts,
  (accounts) => {
    return accounts.reduce((final, { name, target, debt, current, monthly }) => {
      const dailyTotal = (monthly * 12) / 365
      const targetDays = debt ? _.round((current - target) / dailyTotal, 1) : _.round((target - current) / dailyTotal, 1)
      const invalid = targetDays < 0
      const targetWeeks = _.round((targetDays / 365) * 52, 1)
      const targetMonths = _.round((targetDays / 365) * 12, 1)
      const targetYears = _.round(targetDays / 365, 1)
      return final.concat({
        name,
        targetDays,
        targetWeeks,
        targetMonths,
        targetYears,
        invalid,
      })
    }, [])
  }
)
//get 24 months of account and spending money projections;
export const getProjectionsByMonth = createSelector(
  (state) => state,
  (profileState) => {
    const { accounts } = profileState
    const stats = statsFromProfileState(profileState)

    let resultData = []

    let monthlyAmounts = [
      // {
      //   month : '',
      //   income : 1000
      //   expense : 1000
      // }
    ]

    let monthlyExpenses = [
      // {
      //   ts : '',
      //   amount : 1000
      // }
    ]

    for (var month = 0; month < 24; month++) {
      //const amounts = getCalculatedAmount({amountItems : })

      const startDate = profileState.timeStart === 'now' ? DateTime.local() : DateTime.fromMillis(profileState.timeStart)

      const ts = startDate.plus({ month: month }).startOf('month')
      const te = ts.endOf('month')

      const monthStats = statsFromProfileState(profileState, ts, te)
      // const totalCalculatedIncome = getCalculatedAmount({ amountItems: profileState.incomes, ts, te })

      // const totalCalculatedExpense = getCalculatedAmount({ amountItems: profileState.expenses, ts, te })

      monthlyAmounts.push({
        ts,
        //accountStart: monthStats.accountsTotal,
        'Accounts Total': month > 0 ? Math.round(monthlyAmounts[month - 1]['Accounts Total'] + monthStats.netTotal - stats.spendingMonthly) : Math.round(monthStats.netTotal + stats.accountsTotal - stats.spendingMonthly),
        'Accounts Net': Math.round(monthStats.netTotal - stats.spendingMonthly),
        //spending: monthStats.spendingMonthly,
        // net: Math.round(monthStats.netTotal),
        // income: monthStats.totalCalculatedIncome,
        // expense: monthStats.totalCalculatedExpense,
      })

      let extraSpending = 0
      let thisMonth = {}
      for (var i = 0; i < accounts.length; i++) {
        const account = accounts[i]
        const projectedAmount = account.monthly * (account.debt ? -1 * month : month) + account.current

        //check for goals
        if (account.debt && projectedAmount < account.target) {
          extraSpending += account.monthly
          thisMonth[account.name] = account.target
        } else if (!account.debt && projectedAmount > account.target) {
          extraSpending += account.monthly
          thisMonth[account.name] = account.target
        } else {
          thisMonth[account.name] = projectedAmount
        }
      }
      //thisMonth['spending-money'] = stats.monthlyTotal + extraSpending
      resultData.push(thisMonth)
    }

    return { accounts: resultData, amounts: monthlyAmounts }
  }
)

//get 26 weeks of account and spending money projections;
export const getProjectionsByWeek = createSelector(
  (state) => state,
  (profileState) => {
    const { accounts } = profileState
    const stats = statsFromProfileState(profileState)

    let resultData = []

    let weeklyAmounts = [
      // {
      //   month : '',
      //   income : 1000
      //   expense : 1000
      // }
    ]

    let monthlyExpenses = [
      // {
      //   ts : '',
      //   amount : 1000
      // }
    ]

    for (var week = 0; week < 60; week++) {
      //const amounts = getCalculatedAmount({amountItems : })

      const startDate = profileState.timeStart === 'now' ? DateTime.local() : DateTime.fromMillis(profileState.timeStart)

      const ts = startDate.plus({ week: week }).startOf('week')
      const te = ts.endOf('week')

      const weekStats = statsFromProfileState(profileState, ts, te)
      // const totalCalculatedIncome = getCalculatedAmount({ amountItems: profileState.incomes, ts, te })

      // const totalCalculatedExpense = getCalculatedAmount({ amountItems: profileState.expenses, ts, te })

      const weeklySpending = (stats.spendingMonthly * 12) / 52

      weeklyAmounts.push({
        ts,
        //startTime: ts.toFormat('DD'),
        //accountStart: monthStats.accountsTotal,
        'Accounts Total': week > 0 ? Math.round(weeklyAmounts[week - 1]['Accounts Total'] + weekStats.netTotal - weeklySpending) : Math.round(weekStats.netTotal + stats.accountsTotal - weeklySpending),
        'Accounts Net': Math.round(weekStats.netTotal - weeklySpending),
        //spending: monthStats.spendingMonthly,
        // net: Math.round(monthStats.netTotal),
        // income: monthStats.totalCalculatedIncome,
        // expense: monthStats.totalCalculatedExpense,
      })

      let extraSpending = 0
      let thisMonth = {}
      for (var i = 0; i < accounts.length; i++) {
        const account = accounts[i]
        const projectedAmount = ((account.monthly * 12) / 52) * (account.debt ? -1 * week : week) + account.current

        //check for goals
        if (account.debt && projectedAmount < account.target) {
          extraSpending += (account.monthly * 12) / 52
          thisMonth[account.name] = account.target
        } else if (!account.debt && projectedAmount > account.target) {
          extraSpending += (account.monthly * 12) / 52
          thisMonth[account.name] = account.target
        } else {
          thisMonth[account.name] = projectedAmount
        }
      }
      //thisMonth['spending-money'] = stats.monthlyTotal + extraSpending
      resultData.push(thisMonth)
    }

    return { accounts: resultData, amounts: weeklyAmounts }
  }
)
