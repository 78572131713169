import React, { useState } from 'react'
import PropTypes from 'prop-types'

//material ui
import { Grid, Typography, Button, ListItem, TextField, FormControl, Select, InputLabel, MenuItem } from '@material-ui/core'

import { DateTime } from 'luxon'

import DeleteSharp from '@material-ui/icons/DeleteSharp'
import EditSharp from '@material-ui/icons/EditSharp'
import Check from '@material-ui/icons/Check'
import Cancel from '@material-ui/icons/Cancel'

function renderInterval(intervalSlug) {
  switch (intervalSlug) {
    case 'weekly':
      return 'Weekly'
    case 'monthly':
      return 'Monthly'
    case 'biWeekly':
      return 'Bi-Weekly'
    case 'once':
      return 'Once'
    default:
      return 'Unknown'
  }
}

const EditableListItem = (props) => {
  //should use ID system instead of array index
  const { id } = props.data
  const initialState = {
    editing: false,
    name: props.data.name,
    amount: props.data.amount,
    interval: props.data.interval,
    basis: props.data.basis,
  }

  const [{ editing, name, amount, interval, basis }, setState] = useState(initialState)
  const onChange = (e) => {
    const { name, value, type } = e.target
    setState((prevState) => ({ ...prevState, [name]: type === 'number' ? parseInt(value) : value }))
  }
  const onSave = () => {
    props.onEdit({ amount, name, id, interval, basis })
    setState({ amount, name, editing: false, interval, basis })
  }
  const onDelete = () => {
    setState({ editing: false })
    props.onDelete({ amount, name, id, interval, basis })
  }
  return (
    <ListItem key={id} style={{ backgroundColor: '#565656', marginBottom: 5 }}>
      <Grid justify="flex-end" alignItems="center" container spacing={1}>
        {editing ? (
          <Grid item xs={4}>
            <TextField label={props.label} value={name} name="name" onChange={onChange} margin="dense" variant="outlined" />
          </Grid>
        ) : (
          <Grid item style={{ flexGrow: 1 }}>
            <Typography type="title" color="inherit">
              {props.data.name}
            </Typography>
          </Grid>
        )}

        {editing ? (
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel id={'interval-select-label-' + id}>Interval</InputLabel>
              <Select labelId={'interval-select-label-' + id} id="interval-select" value={interval} label="Interval" name="interval" onChange={onChange}>
                <MenuItem value="weekly">Weekly</MenuItem>
                <MenuItem value="biWeekly">Bi-Weekly</MenuItem>
                <MenuItem value="monthly">Monthly</MenuItem>
                <MenuItem value="once">Once</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        ) : (
          <Grid item>
            <Typography type="title" color="inherit">
              {renderInterval(interval)}
            </Typography>
          </Grid>
        )}

        {editing ? (
          <Grid item xs={4}>
            <TextField type="number" label="Amount" name="amount" onChange={onChange} value={amount} margin="dense" variant="outlined" />
          </Grid>
        ) : (
          <Grid item>
            <Typography type="title" color="inherit">
              ${props.data.amount}
            </Typography>
          </Grid>
        )}
        {editing && (
          <Grid item xs={12}>
            <TextField
              name="basis"
              label="basis"
              type="date"
              value={DateTime.fromMillis(basis).toISODate()}
              onChange={(e) => {
                onChange({
                  target: {
                    name: 'basis',
                    type: 'number',
                    value: DateTime.fromISO(e.target.value).toMillis(),
                  },
                })
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        )}
        {editing ? (
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button raised={1} onClick={onSave}>
              <Check fontSize="small" />
            </Button>
            <Button raised={1} onClick={() => setState(initialState)}>
              <Cancel fontSize="small" />
            </Button>
            <Button raised={1} onClick={onDelete}>
              <DeleteSharp fontSize="small" />
            </Button>
          </Grid>
        ) : (
          <Grid item>
            <Button raised={1} onClick={() => setState((prevState) => ({ ...prevState, editing: true }))}>
              <EditSharp />
            </Button>
          </Grid>
        )}
      </Grid>
    </ListItem>
  )
}

EditableListItem.defaultProps = {}

EditableListItem.propTypes = {
  date: PropTypes.object,
  label: PropTypes.string,
}

export default EditableListItem
