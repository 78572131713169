import rootReducer from '../reducers'
import { createStore } from 'redux'

import { makeProfile } from '../utils'
import { decrypt } from '../utils/encrypt'

const initialState = {
  currentTime: 0,
  currentProfile: 0,
  profiles: {
    0: makeProfile(),
  },
  profileCount: 1,
}

const urlSearchParams = new URLSearchParams(window.location.search)
const params = Object.fromEntries(urlSearchParams.entries())
let paramData
if (params.data) {
  try {
    paramData = decrypt(params.data)
    const confirmed = window.confirm('This will replace your current data. Are you sure?')
    if (!confirmed) {
      paramData = undefined
    }
  } catch (error) {
    console.log(error)
    window.alert('Improper format. Check your JSON.')
  }
}
//get localstorage state
const persistedState = paramData ? JSON.parse(paramData) : localStorage.getItem('burnrateStateV4') ? JSON.parse(localStorage.getItem('burnrateStateV4')) : initialState

const store = createStore(rootReducer, persistedState, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())

export default store
