import React from 'react'

//material ui
import { Grid, Paper, Typography, Button, IconButton, TextField } from '@material-ui/core'

//styles
import Chart from 'react-apexcharts'

//icons
import Settings from '@material-ui/icons/Settings'

import moment from 'moment'

//redux
import { connect } from 'react-redux'
//selectors
import { getAccountProjections, getBurnrateStats, getProjectionsByMonth, getProjectionsByWeek } from '../selectors'
//actions
import { changeTime, editProfile } from '../actions'

//utils
import { DateTime } from 'luxon'

//components
import HelperTooltip from './Shared/HelperTooltip'

const mapStateToProps = (state) => {
  return {
    accountProjections: getAccountProjections(state.profiles[state.currentProfile].accounts),
    accounts: state.profiles[state.currentProfile].accounts,
    currentTime: state.currentTime,
    stats: getBurnrateStats(state.profiles[state.currentProfile]),
    projectionsByMonth: getProjectionsByMonth(state.profiles[state.currentProfile]),
    projectionsByWeek: getProjectionsByWeek(state.profiles[state.currentProfile]),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    editProfile: (edits) => dispatch(editProfile(edits)),
    changeTime: (month) => dispatch(changeTime(month)),
  }
}

const TimeTravel = (props) => {
  const { timeStart, timeEnd } = props.profile
  const { currentTime, projectionsByMonth, projectionsByWeek } = props

  const dt = DateTime.local()
  const ts = timeStart === 'now' ? DateTime.local() : DateTime.fromMillis(timeStart)

  const handleDateChange = (e) => {
    props.editProfile({
      [e.target.name]: DateTime.fromISO(e.target.value).toMillis(),
    })
  }

  let chartOptions = {
    options: {
      chart: {
        id: 'basic-line',
        stacked: false,
      },
      stroke: {
        show: true,

        width: 4,
      },
      xaxis: {
        type: 'datetime',
        labels: {
          formatter: (week) => {
            return moment(ts.toISODate()).add(week, 'weeks').format('MMMM D, YY')
          },
        },
      },
      yaxis: {
        forceNiceScale: true,
        labels: {
          formatter: (amount) => {
            return '$' + amount
          },
        },
      },
      theme: {
        mode: 'dark',
        monochrome: {
          enabled: true,
          color: '#ca5656',
          shadeTo: 'light',
          shadeIntensity: 1,
        },
      },
      dataLabels: {
        enabled: false,
      },
    },

    series: {},
  }
  let seriesObj = {
    //accounts: [],
  }
  let amounts = []
  //accountProjections
  // for (var i = 0; i < 11; i++) {
  //   const month = projectionsByMonth.accounts[i]
  //   const monthAmounts = projectionsByMonth.amounts[i]

  //   let accountsTotal = 0
  //   // for (let key in month) {
  //   //   if (key === 'spending-money') {
  //   //     seriesObj['spendingMoney'].push([i, Math.round(month[key])])
  //   //     continue
  //   //   }
  //   //   accountsTotal += month[key]
  //   // }

  //   // seriesObj['accounts'].push([i, Math.round(accountsTotal)])

  //   for (let key in monthAmounts) {
  //     if (key === 'ts') {
  //       continue
  //     }
  //     if (!seriesObj[key]) {
  //       seriesObj[key] = []
  //     }
  //     seriesObj[key].push([i, monthAmounts[key]])
  //   }
  //   //amounts.push(monthAmounts)
  // }

  //accountProjections
  for (var i = 0; i < 60; i++) {
    const weekAmounts = projectionsByWeek.amounts[i]

    let accountsTotal = 0
    // for (let key in month) {
    //   if (key === 'spending-money') {
    //     seriesObj['spendingMoney'].push([i, Math.round(month[key])])
    //     continue
    //   }
    //   accountsTotal += month[key]
    // }

    // seriesObj['accounts'].push([i, Math.round(accountsTotal)])

    for (let key in weekAmounts) {
      if (key === 'ts') {
        continue
      }
      if (!seriesObj[key]) {
        seriesObj[key] = []
      }
      seriesObj[key].push([i, weekAmounts[key]])
    }
    //amounts.push(monthAmounts)
  }

  chartOptions.series = Object.keys(seriesObj).map((key) => {
    return { name: key, data: seriesObj[key] }
  })

  return (
    <Paper style={{ padding: 20 }}>
      <Grid container space={1} alignItems="center">
        <Grid item>
          <Typography gutterBottom variant="h5" component="h2">
            Projections
          </Typography>
        </Grid>
        <Grid item style={{ flexGrow: 1 }} />
        <Grid item>
          <IconButton aria-label="info">
            <Settings fontSize="small" />
          </IconButton>
        </Grid>
        <Grid item>
          <HelperTooltip title="What's this for?">Adjust the time slider to project account balances.</HelperTooltip>
        </Grid>
      </Grid>
      <hr />
      <Grid container space={1} alignItems="center">
        <Grid item>
          <Typography gutterBottom component="h2" variant="h6">
            {currentTime === 0 ? 'Now' : dt.plus({ months: currentTime }).monthShort + ' ' + dt.plus({ months: currentTime }).year}
          </Typography>
        </Grid>
        <Grid item style={{ flexGrow: 1 }} />
        <Grid item>
          <TextField
            name="timeStart"
            label="Start Date"
            type="date"
            value={ts.toISODate()}
            onChange={handleDateChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>
      <div>
        <Chart options={chartOptions.options} series={chartOptions.series} type="line" />
      </div>
      <hr />
      <Grid alignItems="center" container spacing={2}>
        <Grid item>
          <Button variant="contained" raised={1}>
            Graphs
          </Button>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(TimeTravel)
