import _ from 'lodash'

import { makeProfile } from '../utils'

import {
  CHANGE_TIME,
  REPLACE_STATE,
  CHILD_PROFILE,
  COPY_PROFILE,
  ADD_ACCOUNT,
  EDIT_ACCOUNT,
  DELETE_ACCOUNT,
  ADD_PROFILE,
  SWITCH_PROFILE,
  RENAME_PROFILE,
  EDIT_PROFILE,
  DELETE_PROFILE,
  CLEAR_STATE,
  ADD_INCOME,
  ADD_EXPENSE,
  EDIT_INCOME,
  EDIT_EXPENSE,
  DELETE_EXPENSE,
  DELETE_INCOME,
} from '../constants/action-types'

const initialState = {
  currentTime: 0,
  currentProfile: 0,
  profiles: {
    0: makeProfile(),
  },
  profileCount: 1,
}

const removeKey = (key, { [key]: _, ...rest }) => rest

//helper function to update item in array
function updateArrayItem(currentArray, editedItem) {
  return currentArray.map((d) => {
    if (editedItem.id === d.id) {
      return editedItem
    }
    return d
  })
}

function rootReducer(state = initialState, action) {
  if (action.type === REPLACE_STATE) {
    return action.payload
  }
  if (action.type === CHANGE_TIME) {
    return { ...state, currentTime: action.payload }
  }
  if (action.type === ADD_PROFILE) {
    return {
      ...state,
      profileCount: state.profileCount + 1,
      profiles: { ...state.profiles, [state.profileCount + 1]: { ...makeProfile(), id: state.profileCount + 1, name: action.payload.name } },
      currentProfile: `${state.profileCount + 1}`,
    }
  }
  if (action.type === COPY_PROFILE) {
    return {
      ...state,
      profileCount: state.profileCount + 1,
      profiles: { ...state.profiles, [state.profileCount + 1]: { ...action.payload, id: state.profileCount + 1, name: action.payload.name + ' - Copy' } },
      currentProfile: state.profileCount + 1,
    }
  }
  if (action.type === CHILD_PROFILE) {
    return {
      ...state,
      profileCount: state.profileCount + 1,
      profiles: { ...state.profiles, [state.profileCount + 1]: { ...action.payload, parent: action.payload.id, id: state.profileCount + 1, name: action.payload.name + ' - Child' } },
      currentProfile: state.profileCount + 1,
    }
  }
  if (action.type === SWITCH_PROFILE) {
    return { ...state, currentProfile: action.payload.toString() }
  }
  if (action.type === RENAME_PROFILE) {
    return {
      ...state,
      profiles: {
        ...state.profiles,
        [action.payload.id]: {
          ...state.profiles[action.payload.id],
          ...action.payload,
        },
      },
    }
  }
  if (action.type === EDIT_PROFILE) {
    return {
      ...state,
      profiles: {
        ...state.profiles,
        [state.currentProfile]: {
          ...state.profiles[state.currentProfile],
          ...action.payload,
        },
      },
    }
  }

  if (action.type === DELETE_PROFILE) {
    const draftState = { ...state }
    let draftProfileState = state.profiles

    draftProfileState = removeKey(state.currentProfile, draftProfileState)

    draftState.profiles = draftProfileState
    draftState.currentProfile = Object.keys(draftState.profiles)[0]

    if (draftState.currentProfile === undefined) {
      window.alert('Must keep 1 profile')

      return state
    }

    return draftState
  }

  if (action.type === ADD_INCOME) {
    const account = action.payload
    return {
      ...state,
      profiles: {
        ...state.profiles,
        [state.currentProfile]: {
          ...state.profiles[state.currentProfile],
          incomes: state.profiles[state.currentProfile].incomes.concat({ ...account, id: state.profiles[state.currentProfile].incomesCount }),
          incomesCount: state.profiles[state.currentProfile].incomesCount + 1,
        },
      },
    }
  }
  if (action.type === ADD_EXPENSE) {
    const expense = action.payload
    return {
      ...state,
      profiles: {
        ...state.profiles,
        [state.currentProfile]: {
          ...state.profiles[state.currentProfile],
          expenses: state.profiles[state.currentProfile].expenses.concat({ ...expense, id: state.profiles[state.currentProfile].expensesCount }),
          expensesCount: state.profiles[state.currentProfile].expensesCount + 1,
        },
      },
    }
  }
  if (action.type === EDIT_INCOME) {
    // payload = {index,name,amount}
    const income = action.payload
    return { ...state, profiles: { ...state.profiles, [state.currentProfile]: { ...state.profiles[state.currentProfile], incomes: updateArrayItem(state.profiles[state.currentProfile].incomes, income) } } }
  }
  if (action.type === EDIT_EXPENSE) {
    // payload = {index,name,amount}
    const expense = action.payload
    return { ...state, profiles: { ...state.profiles, [state.currentProfile]: { ...state.profiles[state.currentProfile], expenses: updateArrayItem(state.profiles[state.currentProfile].expenses, expense) } } }
  }
  if (action.type === DELETE_EXPENSE) {
    // payload = {index,name,amount}
    const expense = action.payload
    return { ...state, profiles: { ...state.profiles, [state.currentProfile]: { ...state.profiles[state.currentProfile], expenses: _.filter(state.profiles[state.currentProfile].expenses, (exp) => exp.id !== expense.id) } } }
  }
  if (action.type === DELETE_INCOME) {
    // payload = {index,name,amount}
    const income = action.payload
    return { ...state, profiles: { ...state.profiles, [state.currentProfile]: { ...state.profiles[state.currentProfile], incomes: _.filter(state.profiles[state.currentProfile].incomes, (d) => d.id !== income.id) } } }
  }
  //accounts
  if (action.type === ADD_ACCOUNT) {
    const account = action.payload
    return {
      ...state,
      profiles: {
        ...state.profiles,
        [state.currentProfile]: {
          ...state.profiles[state.currentProfile],
          accounts: state.profiles[state.currentProfile].accounts.concat({ ...account, id: state.profiles[state.currentProfile].accountsCount }),
          accountsCount: state.profiles[state.currentProfile].accountsCount + 1,
        },
      },
    }
  }
  if (action.type === EDIT_ACCOUNT) {
    // payload = {index,name,amount}
    const account = action.payload
    return { ...state, profiles: { ...state.profiles, [state.currentProfile]: { ...state.profiles[state.currentProfile], accounts: updateArrayItem(state.profiles[state.currentProfile].accounts, account) } } }
  }
  if (action.type === DELETE_ACCOUNT) {
    // payload = {index,name,amount}
    const account = action.payload
    return { ...state, profiles: { ...state.profiles, [state.currentProfile]: { ...state.profiles[state.currentProfile], accounts: _.filter(state.profiles[state.currentProfile].accounts, (d) => d.id !== account.id) } } }
  }
  if (action.type === CLEAR_STATE) {
    return initialState
  }

  //no matching dispatch
  console.log('WARNING: action type ' + action.type + ' was called, but does not seem to effect state')
  return state
}

export default rootReducer
