import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'

//material ui
import { Grid, Badge, Typography, Button, ListItem, TextField, Checkbox, FormControlLabel, Chip } from '@material-ui/core'

import DeleteSharp from '@material-ui/icons/DeleteSharp'
import EditSharp from '@material-ui/icons/EditSharp'
import Check from '@material-ui/icons/Check'
import Cancel from '@material-ui/icons/Cancel'

import { numberWithCommas } from '../utils'

import _ from 'lodash'
import { DateTime } from 'luxon'

const EditableAccountItem = (props) => {
  const { netTotal, monthlyAmount, timeStartDT, durationInMonths } = props
  const { id } = props.data
  const initialState = {
    editing: false,
    debt: props.data.debt,
    name: props.data.name,
    current: props.data.current,
    target: props.data.target,
    monthly: props.data.monthly,
    spendAmount: props.data.spendAmount,
    spend: props.data.spend,
  }

  const [{ editing, name, current, target, monthly, debt, spend, spendAmount }, setState] = useState(initialState)

  const onChange = (e) => {
    const { name, value, type } = e.target
    switch (type) {
      case 'checkbox':
        setState((prevState) => ({ ...prevState, [name]: e.target.checked }))
        break
      case 'number':
        if (name === 'monthly') {
          if (value > 100 || value < -100) {
            return
          }
        }
        setState((prevState) => ({ ...prevState, [name]: !isNaN(parseInt(value)) ? parseInt(value) : 0 }))
        break
      default:
        setState((prevState) => ({ ...prevState, [name]: value }))
        break
    }
  }
  const onSave = () => {
    props.onEdit({ debt, current, name, id, target, monthly, spend, spendAmount })
    setState({ debt, current, name, target, monthly, editing: false, spend, spendAmount })
  }
  const onDelete = () => {
    setState({ editing: false })
    props.onDelete({ current, name, id })
  }
  return (
    <ListItem style={{ backgroundColor: '#565656', marginBottom: 5 }}>
      <Grid direction="row" justify="space-between" alignItems="center" container spacing={0}>
        {editing ? (
          <React.Fragment>
            <Grid item xs={8}>
              <TextField label="Name" value={name} name="name" onChange={onChange} margin="dense" variant="outlined" />
            </Grid>
            {/* <Grid item xs={4} style={{ display: 'flex', justifyContent: 'center' }}>
              <FormControlLabel control={<Checkbox label="Debt" name="debt" checked={debt} onChange={onChange} value="primary" inputProps={{ 'aria-label': 'primary checkbox' }} />} label="Debt" />
            </Grid> */}
            <Grid item xs={4}>
              <TextField type="number" label="Balance" name="current" onChange={onChange} value={current} margin="dense" variant="outlined" />
            </Grid>
            <Grid item xs={4}>
              <TextField type="number" label="Monthly %" name="monthly" onChange={onChange} value={monthly} margin="dense" variant="outlined" />
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button raised={1} onClick={onSave}>
                <Check fontSize="small" />
              </Button>
              <Button raised={1} onClick={() => setState(initialState)}>
                <Cancel fontSize="small" />
              </Button>
              <Button raised={1} onClick={onDelete}>
                <DeleteSharp fontSize="small" />
              </Button>
            </Grid>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Grid item xs={12} md={12}>
              <Typography type="title" variant="h6" color="inherit">
                {props.data.name}
              </Typography>
              <hr />
            </Grid>
            <Grid item xs={2}>
              <Typography type="title" color="inherit">
                Balance
                <br />${props.data.current}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography type="title" color="inherit">
                Projected
                <br />${props.projectedTotal}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography type="title" color="inherit">
                Monthly
                <br />
                {props.data.monthly}% | ${monthlyAmount}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Button raised={1} onClick={() => setState((prevState) => ({ ...prevState, editing: true }))}>
                <EditSharp fontSize="small" />
              </Button>
            </Grid>
          </React.Fragment>
        )}
      </Grid>
    </ListItem>
  )
}

EditableAccountItem.defaultProps = {}

EditableAccountItem.propTypes = {
  date: PropTypes.object,
  label: PropTypes.string,
}

export default EditableAccountItem
