import {
  ADD_ACCOUNT,
  EDIT_ACCOUNT,
  DELETE_ACCOUNT,
  CHILD_PROFILE,
  ADD_PROFILE,
  COPY_PROFILE,
  SWITCH_PROFILE,
  RENAME_PROFILE,
  EDIT_PROFILE,
  DELETE_PROFILE,
  CLEAR_STATE,
  ADD_INCOME,
  EDIT_INCOME,
  ADD_EXPENSE,
  EDIT_EXPENSE,
  DELETE_EXPENSE,
  DELETE_INCOME,
  SET_TARGET,
  SET_CURRENT,
  REPLACE_STATE,
  CHANGE_TIME,
} from '../constants/action-types'

export function addIncome(payload) {
  return { type: ADD_INCOME, payload }
}

export function editIncome(payload) {
  return { type: EDIT_INCOME, payload }
}

export function addExpense(payload) {
  return { type: ADD_EXPENSE, payload }
}

export function editExpense(payload) {
  return { type: EDIT_EXPENSE, payload }
}
export function deleteExpense(payload) {
  return { type: DELETE_EXPENSE, payload }
}
export function deleteIncome(payload) {
  return { type: DELETE_INCOME, payload }
}

export function setTarget(payload) {
  return { type: SET_TARGET, payload }
}
export function setCurrent(payload) {
  return { type: SET_CURRENT, payload }
}
export function addProfile(payload) {
  return { type: ADD_PROFILE, payload }
}
export function childProfile(payload) {
  return { type: CHILD_PROFILE, payload }
}
export function copyProfile(payload) {
  return { type: COPY_PROFILE, payload }
}
export function switchProfile(payload) {
  return { type: SWITCH_PROFILE, payload }
}
export function renameProfile(payload) {
  return { type: RENAME_PROFILE, payload }
}
export function editProfile(payload) {
  return { type: EDIT_PROFILE, payload }
}
export function deleteProfile(payload) {
  return { type: DELETE_PROFILE, payload }
}

export function addAccount(payload) {
  return { type: ADD_ACCOUNT, payload }
}

export function deleteAccount(payload) {
  return { type: DELETE_ACCOUNT, payload }
}

export function editAccount(payload) {
  return { type: EDIT_ACCOUNT, payload }
}

export function clearState(payload) {
  return { type: CLEAR_STATE, payload }
}

export function replaceState(payload) {
  return { type: REPLACE_STATE, payload }
}

export function changeTime(payload) {
  return { type: CHANGE_TIME, payload }
}
