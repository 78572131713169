import React from 'react'

//material ui
import { Tooltip, IconButton, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import HelpRounded from '@material-ui/icons/HelpRounded'

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip)

const HelperTooltip = (props) => {
  return (
    <HtmlTooltip
      arrow
      enterTouchDelay={50}
      leaveTouchDelay={3000}
      title={
        <React.Fragment>
          <Typography color="inherit">{props.title}</Typography>
          {props.children}
        </React.Fragment>
      }
    >
      <IconButton aria-label="info">
        <HelpRounded fontSize="small" />
      </IconButton>
    </HtmlTooltip>
  )
}

export default HelperTooltip
