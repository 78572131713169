import React from 'react'
import './App.css'

import { version as VERSION } from '../package.json'

import { AppBar, Toolbar, Button, Grid } from '@material-ui/core'
import Box from '@material-ui/core/Box'

import { makeStyles } from '@material-ui/core/styles'

import { encrypt, decrypt } from './utils/encrypt'

import moment from 'moment'

//icons
import Whatshot from '@material-ui/icons/Whatshot'
import Add from '@material-ui/icons/Add'
import GitHub from '@material-ui/icons/GitHub'
import Save from '@material-ui/icons/CloudDownload'
import Load from '@material-ui/icons/CloudUpload'
import Share from '@material-ui/icons/Share'
import Check from '@material-ui/icons/Check'

//components
import Main from './components/Main'
import ProfileButton from './components/ProfileButton.jsx'
import SideDrawer from './components/SideDrawer'

//redux
import { connect } from 'react-redux'

//selectors
import { getBurnrateStats } from './selectors'

//store
import store from './store'

import { addProfile, switchProfile, renameProfile, replaceState } from './actions'

const mapStateToProps = (state) => {
  return { state, currentProfile: state.currentProfile, profiles: state.profiles, stats: getBurnrateStats }
}

function mapDispatchToProps(dispatch) {
  return {
    replaceState: (payload) => dispatch(replaceState(payload)),
    addProfile: (name) => dispatch(addProfile(name)),
    switchProfile: (profileId) => dispatch(switchProfile(profileId)),
    renameProfile: (payload) => dispatch(renameProfile(payload)),
  }
}

//store persistence
store.subscribe(() => {
  const storeStateStr = JSON.stringify(store.getState())
  //save locally -- this should probably be an option?
  localStorage.setItem('burnrateStateV4', storeStateStr)
})

//should be material UI modal?
function getNameAddProfile(addProfileFunc) {
  const profileName = prompt('Profile Name?')
  //ensure data
  if (!profileName || profileName === '') {
    return
  }
  addProfileFunc({ name: profileName })
}
function changeProfileName(changeProfileFunc, profile) {
  const profileName = prompt('Change Profile Name?', profile.name)
  //ensure data
  if (!profileName || profileName === '') {
    return
  }
  changeProfileFunc({ id: profile.id, name: profileName })
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  profileButtons: {},
}))

function App(props) {
  const { state: appState, currentProfile, profiles, replaceState } = props

  const [state, setState] = React.useState({
    drawerOpen: false,
  })

  const [shareUrl, setShareUrl] = React.useState()

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    setState({ ...state, drawerOpen: open })
  }

  const handleLoad = (e) => {
    if (e.target.files.length < 1) {
      return false
    }
    const fileReader = new FileReader()

    fileReader.onload = function (e) {
      try {
        var result = JSON.parse(e.target.result)
        //should really validate this...
        replaceState(result)
      } catch (error) {
        console.log(error)
        window.alert('Improper format. Check your JSON.')
      }
    }
    fileReader.readAsText(e.target.files.item(0))
  }

  const handleSave = async () => {
    const fileName = 'burnrate-' + moment().format('MM-DD-Y')
    const json = JSON.stringify(appState)
    const blob = new Blob([json], { type: 'application/json' })
    const href = await URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = href
    link.download = fileName + '.json'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const handleShare = async () => {
    const eData = encrypt(JSON.stringify(appState))
    const url = window.location.origin + '/?data=' + eData
    setShareUrl(url)
  }

  const handleCopy = async () => {
    navigator.clipboard.writeText(shareUrl)
    setShareUrl('DONE')
    setTimeout(() => {
      setShareUrl()
    }, 3000)
  }

  const classes = useStyles()

  return (
    <Box>
      <div className={classes.root}>
        <AppBar color="primary" position="static">
          <Toolbar>
            <Grid container>
              <Grid item xs={4} md={6} style={{ padding: 5, display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                <Button
                  edge="start"
                  className={classes.menuButton}
                  id="icon"
                  startIcon={<Whatshot />}
                  onClick={() => {
                    /**  */
                  }}
                  aria-label="menu"
                >
                  burnrate
                </Button>

                {/* {signedInBlockStack && <Button color="inherit" raised={1} endIcon={<img style={{height:40,width:40}} src={"/images/blockstack.png"} />}>{blockstack.loadUserData().username}</Button>}
            {!signedInBlockStack && <Button onClick={()=>queueBlockstackLogin()} color="inherit" raised={1} endIcon={<img style={{height:40,width:40,opacity:.5}} src={"/images/blockstack.png"} />}></Button>} */}
              </Grid>
              <Grid item xs={8} md={6} style={{ padding: 5, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <div>
                  <input type="file" id="file" name="file" style={{ opacity: 0, position: 'fixed' }} onChange={handleLoad} />
                  <label htmlFor="file">
                    <Button id="icon" endIcon={<Load />} onClick={(e) => document.getElementById('file').click()}>
                      Load
                    </Button>
                  </label>
                </div>

                <Button id="save" endIcon={<Save />} onClick={handleSave}>
                  Save
                </Button>

                {!shareUrl ? (
                  <Button id="share" endIcon={<Share />} onClick={handleShare}>
                    Share
                  </Button>
                ) : shareUrl !== 'DONE' ? (
                  <Button id="share" variant="outlined" endIcon={<Share />} onClick={handleCopy}>
                    Copy URL
                  </Button>
                ) : (
                  <Button id="share" variant="outlined" endIcon={<Check />} disabled={true}>
                    Copied!
                  </Button>
                )}

                <Button id="version" endIcon={<GitHub />} onClick={() => window.open('https://github.com/nucleargriff/burnrate-web', '_blank')}>
                  version {VERSION}
                </Button>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <AppBar color="secondary" position="static">
          <Grid container style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
            {Object.keys(profiles).map((profileKey, index) => (
              <Grid item xs={12} md={2} key={index}>
                <ProfileButton
                  style={{ marginLeft: 3, marginRight: 3 }}
                  onDoubleClick={() => changeProfileName(props.renameProfile, profiles[profileKey])}
                  variant={profileKey === currentProfile ? 'contained' : 'outlined'}
                  onClick={() => props.switchProfile(profileKey)}
                  stats={getBurnrateStats(profiles[profileKey])}
                  name={profiles[profileKey].name}
                />
              </Grid>
            ))}
            <Grid item xs={12} md={1}>
              <Button fullWidth={true} raised={1} onClick={() => getNameAddProfile(props.addProfile)}>
                <Add />
              </Button>
            </Grid>
          </Grid>
        </AppBar>
      </div>
      <Main profile={profiles[currentProfile]} />
      <SideDrawer open={state.drawerOpen} toggleDrawer={toggleDrawer} />
    </Box>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
