import { DateTime } from 'luxon'

export const makeProfile = () => {
  const dt = DateTime.local()

  return {
    id: 0,
    name: 'New Profile',
    parent: null,
    timeStart: dt.valueOf(),
    timeEnd: dt.plus({ months: 6 }).valueOf(),
    incomes: [], //{id,name, amount}
    incomesCount: 0,
    expenses: [], //{id,name, amount}
    expensesCount: 0,
    accounts: [], //{id,name,current,target,monthly,debt}
    accountsCount: 0,
    targetAccount: 0,
    currentAccount: 0,
  }
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
