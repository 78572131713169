import React from 'react'

//material ui
import { Grid, Paper, Typography } from '@material-ui/core'

import HelperTooltip from './Shared/HelperTooltip'

const SpendingMoney = (props) => {
  const { spendingMonthly, spendingNetTotal } = props.data.stats

  const spendingDaily = (spendingMonthly * 12) / 365
  const spendingWeekly = (spendingMonthly * 12) / 52

  return (
    <Paper style={{ padding: 20 }}>
      <Grid container space={1} alignItems="center">
        <Grid item>
          <Typography gutterBottom variant="h5" component="h2">
            Spending Money
          </Typography>
        </Grid>
        <Grid item style={{ flexGrow: 1 }} />
        <Grid item>
          <HelperTooltip title="What's this for?">Your budget for spending on flexible things like food, transportation, entertainment, etc...</HelperTooltip>
        </Grid>
      </Grid>
      <hr />
      {/* <Typography align="center" component="h2" variant="h5">You are {(dailyTotal > 0) ? "building" : "burning"}:</Typography> */}
      <Grid container spacing={2} justify="space-between">
        <Grid item>
          <Typography align="center" component="h4" variant="h5">
            ${Math.round(spendingDaily)}
            <br /> <small>Daily</small>
          </Typography>
        </Grid>
        <Grid item>
          <Typography align="center" component="h4" variant="h5">
            ${Math.round(spendingWeekly)} <br /> <small>Weekly</small>
          </Typography>
        </Grid>
        <Grid item>
          <Typography align="center" component="h4" variant="h5">
            ${Math.round(spendingMonthly)} <br />
            <small>Monthly</small>
          </Typography>
        </Grid>
        <Grid item>
          <Typography align="center" component="h4" variant="h5">
            ${Math.round(spendingNetTotal)} <br />
            <small>Total</small>
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default SpendingMoney
