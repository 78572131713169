import React from 'react'

//material ui
import { Grid, Paper, Typography, Button, List } from '@material-ui/core'

import AddBoxRounded from '@material-ui/icons/AddBoxRounded'

import { connect } from 'react-redux'

import HelperTooltip from './Shared/HelperTooltip'
import EditableAccountItem from './EditableAccountItem'

//actions
import { addAccount, editAccount, deleteAccount } from '../actions'

import { numberWithCommas } from '../utils'

import { getBurnrateStats } from '../selectors'

const mapStateToProps = (state) => {
  return {
    currentTime: state.currentTime,
    currentProfile: state.currentProfile,
    stats: getBurnrateStats(state.profiles[state.currentProfile]),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addAccount: (account) => dispatch(addAccount(account)),
    editAccount: (account) => dispatch(editAccount(account)),
    deleteAccount: (account) => dispatch(deleteAccount(account)),
  }
}

const Accounts = (props) => {
  const { accounts, currentTime, currentProfile, stats, timeStartDT } = props

  return (
    <Paper style={{ ...props.style }}>
      <Grid container space={1} alignItems="center">
        <Grid item>
          <Typography gutterBottom variant="h5" component="h2">
            Accounts
          </Typography>
        </Grid>
        <Grid item style={{ flexGrow: 1 }} />
        <Grid item>
          <HelperTooltip title="What's this for?">
            Track and project your savings and credit accounts. Check the debt box to track credit / loans. Put how much money you'd like to put into the account each month in the "Monthly" box.
          </HelperTooltip>
        </Grid>
      </Grid>
      <hr />
      <div style={{ maxHeight: 700, overflow: 'auto' }}>
        <List>
          {accounts.map((account, index) => (
            <EditableAccountItem
              key={account.id + currentProfile}
              label="item"
              currentTime={currentTime}
              onDelete={(account) => props.deleteAccount(account)}
              onEdit={(account) => props.editAccount(account)}
              projectedTotal={Math.round(!account.spend ? account.current + stats.netTotal * (account.monthly / 100) : account.current - account.spendAmount)}
              monthlyAmount={Math.floor((account.monthly / 100) * stats.monthlyTotal)}
              timeStartDT={timeStartDT}
              durationInMonths={stats.durationInMonths}
              data={{ ...account, index }}
            />
          ))}
        </List>
      </div>
      <hr />
      <Grid justify="space-between" alignItems="center" container spacing={2}>
        <Grid item>
          <Typography>
            Current: ${numberWithCommas(stats.accountsTotal)} | Projected: ${numberWithCommas(Math.round(stats.accountsTotalProjected))}
          </Typography>
        </Grid>
        <Grid item>
          <Button raised={1} onClick={() => props.addAccount({ name: 'New Account', current: 0, target: 0, monthly: 0, debt: false, spend: false, spendAmount: 0 })}>
            New Account
            <AddBoxRounded />
          </Button>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Accounts)
