import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import CssBaseline from '@material-ui/core/CssBaseline'
import App from './App'
import * as serviceWorker from './serviceWorker'

import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'

import { Provider } from 'react-redux'

import store from './store'

const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#ca5656',
    },
    secondary: {
      main: '#733737',
    },
    danger: {
      contrastText: '#fff',
      main: '#ca5656',
    },
  },
  spacing: 8,
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 13,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
})

window.getStore = () => console.log(store.getState())
window.clearStore = () => store.dispatch({ type: 'CLEAR_STATE' })

const render = (Component) => {
  return ReactDOM.render(
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <App />
      </Provider>
    </ThemeProvider>,
    document.getElementById('root')
  )
}

render(App)

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default
    render(NextApp)
  })
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
