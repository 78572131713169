import sjcl from 'sjcl'

export const encrypt = (dataString: string) => {
  const password = window.prompt('Enter password to encrypt data (leave empty for none)') || ''
  return encodeString(JSON.stringify(sjcl.encrypt(password, dataString)))
}

export const decrypt = (b64String: string) => {
  const password = window.prompt('Enter password to decrypt data (leave empty for none)') || ''
  return sjcl.decrypt(password, JSON.parse(decodeString(b64String)))
}

export function encodeString(str: string) {
  return encodeURIComponent(window.btoa(str))
}

export function decodeString(str: string) {
  return decodeURIComponent(window.atob(str))
}
